import { NextPage } from 'next';
import { SkeletonImageCarousel } from '../ImageCarousel.skeleton';
import { SkeletonChallengeCarousel } from '../ChallengeCarousel.skeleton';
import { SkeletonProductsGrid } from '../ProductsGrid.skeleton';
import SkeletonCardImage from '../CardImage.skeleton';

const PrivateHomeSkeleton: NextPage = (): JSX.Element => {
  return (
    <section className="privateHome-skeleton">
      <SkeletonImageCarousel />
      <SkeletonChallengeCarousel />
      <SkeletonCardImage />
      <SkeletonProductsGrid />
    </section>
  );
};

export default PrivateHomeSkeleton;
