import { Row } from 'antd';
import { SkeletonTitle } from './Title.skeleton';

const SkeletonCardImage = (): JSX.Element => {
  return (
    <section className="skeleton-cardImage">
      <div className="skeleton-cardImage--media" />
      <div className="skeleton-cardImage--text">
        <SkeletonTitle />

        <Row
          justify="center"
          align="middle"
          className="skeleton-challenge-carousel--description"
        >
          <div className="skeleton-challenge-carousel--description__content" />
          <div className="skeleton-challenge-carousel--description__content" />
          <div className="skeleton-challenge-carousel--description__content-mobile" />
          <div className="skeleton-challenge-carousel--description__content" />
        </Row>
        <Row justify="center" align="middle">
          <div className="skeleton-button" />
        </Row>
      </div>
    </section>
  );
};

export default SkeletonCardImage;
