import { NextPage } from 'next';
import { useState, useEffect } from 'react';
import {
  useSize,
  YuFlex,
  YuSkeleton,
  YuSkeletonWrapper,
  YuSpacer,
} from '@isdin/yuma-react-web-pin';

const PublicHomeSkeleton: NextPage = (): JSX.Element | null => {
  const [domLoaded, setDomLoaded] = useState(false);

  useEffect(() => {
    setDomLoaded(true);
  }, []);
  let width = 0;
  if (typeof window !== 'undefined') {
    width = window.innerWidth > 1440 ? 1440 : window.innerWidth;
  }
  const partialHeight = `${width * 0.54}px`;
  const fullHeight = `${width}px`;

  const { upM, upXS } = useSize();
  const SkeletonPublicHomeDesktop = () => {
    return (
      <section className="publicHome-skeleton">
        <YuSkeletonWrapper
          height="520px"
          width="100%"
          backgroundColor="bg.grey"
        >
          <YuFlex flexDirection="column" marginLeft={'L'} marginTop={'L'}>
            <YuSkeleton height="32px" width="45%" />
            <YuSpacer size={'XS'} />
            <YuSkeleton height="32px" width="45%" />
            <YuSpacer size={'XS'} />
            <YuSkeleton height="32px" width="20%" />
            <YuSpacer size={'L'} />
            <YuFlex flexDirection="row" width={'45%'}>
              <YuSkeleton height={'24px'} marginRight={'XS'} width="24px" />
              <YuSkeleton height={'24px'} width="85%" />
            </YuFlex>
            <YuSpacer size={'S'} />
            <YuFlex flexDirection="row" width={'45%'}>
              <YuSkeleton height={'24px'} marginRight={'XS'} width="24px" />
              <YuSkeleton height={'24px'} width="85%" />
            </YuFlex>
            <YuSpacer size={'S'} />
            <YuFlex flexDirection="row" width={'45%'}>
              <YuSkeleton height={'24px'} marginRight={'XS'} width="24px" />
              <YuSkeleton height={'24px'} width="85%" />
            </YuFlex>
            <YuSpacer size={'L'} />
            <YuSpacer size={'XXS'} />
            <YuSkeleton height="48px" width="239px" />
          </YuFlex>
        </YuSkeletonWrapper>

        <YuSpacer size={upM ? 'XL' : 'XXL'} />
        <YuFlex flexDirection="row" width={'100%'}>
          <YuSkeleton height={partialHeight} width="54%" />
          <YuFlex
            flexDirection="column"
            marginLeft="L"
            height="100%"
            width="46%"
            maxWidth={'536px'}
            style={{ marginTop: 'auto', marginBottom: 'auto' }}
          >
            <YuSkeleton height="40px" width="90%" />
            <YuSpacer size={'XS'} />
            <YuSkeleton height="40px" width="90%" />
            <YuSpacer size={'XS'} />
            <YuSkeleton height="40px" width="60%" />
            <YuSpacer size={'S'} />
            <YuSkeleton height="18px" width="90%" />
            <YuSpacer size={'XXS'} />
            <YuSkeleton height="18px" width="90%" />
            <YuSpacer size={'XXS'} />
            <YuSkeleton height="18px" width="40%" />
            <YuSpacer size={'M'} />
            <YuSkeleton height="48px" width="239px" />
          </YuFlex>
        </YuFlex>
        <YuSpacer size={upM ? 'XL' : 'XXL'} />
        <YuSkeletonWrapper
          height="600px"
          width="100%"
          backgroundColor="bg.grey"
        >
          <YuFlex
            flexDirection="column"
            style={{ margin: 'auto' }}
            maxWidth={'874px'}
            width={'100%'}
          >
            <YuSkeleton height="40px" width="90%" alignSelf="center" />
            <YuSpacer size={'XS'} />
            <YuSkeleton height="40px" width="60%" alignSelf="center" />
            <YuSpacer size={'M'} />
            <YuSkeleton height="18px" width="80%" alignSelf="center" />
            <YuSpacer size={'M'} />
            <YuSkeleton height="48px" width="239px" alignSelf="center" />
          </YuFlex>
        </YuSkeletonWrapper>

        <YuSpacer size={upM ? 'XL' : 'XXL'} />
        <YuFlex flexDirection="row" width={'100%'}>
          <YuFlex
            flexDirection="column"
            marginLeft="L"
            marginRight="L"
            height="100%"
            width="46%"
            maxWidth={'536px'}
            style={{ marginTop: 'auto', marginBottom: 'auto' }}
          >
            <YuSkeleton height="40px" width="90%" />
            <YuSpacer size={'XS'} />
            <YuSkeleton height="40px" width="90%" />
            <YuSpacer size={'XS'} />
            <YuSkeleton height="40px" width="60%" />
            <YuSpacer size={'S'} />
            <YuSkeleton height="18px" width="90%" />
            <YuSpacer size={'XXS'} />
            <YuSkeleton height="18px" width="90%" />
            <YuSpacer size={'XXS'} />
            <YuSkeleton height="18px" width="40%" />
            <YuSpacer size={'S'} />
            <YuSkeleton height="48px" width="239px" />
          </YuFlex>
          <YuSkeleton height={partialHeight} width="54%" />
        </YuFlex>
        <YuSpacer size={'XXXL'} />
        <YuSpacer size={upM ? 'XXXS' : 'XL'} />
        <YuSkeleton alignSelf="center" height="48px" width="770px" />
        <YuSpacer size={upM ? 'L' : 'XL'} />
        <YuFlex flexDirection="row" width={'100%'}>
          <YuFlex flexDirection="column" width="33%">
            <YuSkeleton height="100px" alignSelf="center" width="100px" />
            <YuSpacer size={'M'} />
            <YuSkeleton height="28px" alignSelf="center" width="70%" />
            <YuSpacer size={'S'} />
            <YuSkeleton height="18px" alignSelf="center" width="80%" />
            <YuSpacer size={'XXS'} />
            <YuSkeleton height="18px" alignSelf="center" width="90%" />
            <YuSpacer size={'XXS'} />
            <YuSkeleton height="18px" alignSelf="center" width="80%" />
          </YuFlex>
          <YuFlex flexDirection="column" width="34%">
            <YuSkeleton height="100px" alignSelf="center" width="100px" />
            <YuSpacer size={'M'} />
            <YuSkeleton height="28px" alignSelf="center" width="70%" />
            <YuSpacer size={'S'} />
            <YuSkeleton height="18px" alignSelf="center" width="80%" />
            <YuSpacer size={'XXS'} />
            <YuSkeleton height="18px" alignSelf="center" width="90%" />
            <YuSpacer size={'XXS'} />
            <YuSkeleton height="18px" alignSelf="center" width="80%" />
          </YuFlex>
          <YuFlex flexDirection="column" width="33%">
            <YuSkeleton height="100px" alignSelf="center" width="100px" />
            <YuSpacer size={'M'} />
            <YuSkeleton height="28px" alignSelf="center" width="70%" />
            <YuSpacer size={'S'} />
            <YuSkeleton height="18px" alignSelf="center" width="80%" />
            <YuSpacer size={'XXS'} />
            <YuSkeleton height="18px" alignSelf="center" width="90%" />
            <YuSpacer size={'XXS'} />
            <YuSkeleton height="18px" alignSelf="center" width="80%" />
          </YuFlex>
        </YuFlex>
        <YuSpacer size={upM ? 'XL' : 'XXL'} />
        <YuSkeleton height="48px" alignSelf="center" width="239px" />
        <YuSpacer size={upM ? 'XL' : 'XXL'} />
      </section>
    );
  };

  const SkeletonPublicHomeMobile = () => {
    return (
      <YuSkeletonWrapper>
        <YuSpacer size={'XL'} />
        <YuSkeleton height="32px" width="95%" alignSelf="center" />
        <YuSpacer size={'XS'} />
        <YuSkeleton height="32px" width="90%" alignSelf="center" />
        <YuSpacer size={'XS'} />
        <YuSkeleton height="32px" width="50%" alignSelf="center" />
        <YuSpacer size={'L'} />
        <YuFlex flexDirection="row" width={'95%'} alignSelf="center">
          <YuSkeleton height={'24px'} marginRight={'XS'} width="24px" />
          <YuSkeleton height={'24px'} width="85%" />
        </YuFlex>
        <YuSpacer size={'S'} />
        <YuFlex flexDirection="row" width={'95%'} alignSelf="center">
          <YuSkeleton
            height={'24px'}
            marginRight={'XS'}
            width="24px"
            style={{ marginTop: 'auto', marginBottom: 'auto' }}
          />
          <YuFlex flexDirection="column" width="85%">
            <YuSkeleton height={'24px'} width="100%" />
            <YuSpacer size={'XXS'} />
            <YuSkeleton height={'24px'} width="30%" />
          </YuFlex>
        </YuFlex>
        <YuSpacer size={'S'} />
        <YuFlex flexDirection="row" width={'95%'} alignSelf="center">
          <YuSkeleton
            height={'24px'}
            marginRight={'XS'}
            width="24px"
            style={{ marginTop: 'auto', marginBottom: 'auto' }}
          />
          <YuFlex flexDirection="column" width="85%">
            <YuSkeleton height={'24px'} width="100%" />
            <YuSpacer size={'XXS'} />
            <YuSkeleton height={'24px'} width="90%" />
          </YuFlex>
        </YuFlex>
        <YuSpacer size={'L'} />
        <YuSpacer size={'XXS'} />
        <YuSkeleton height="48px" width="239px" alignSelf="center" />
        <YuSpacer size={'XL'} />
        <YuSkeleton height={fullHeight} width="100%" />
        <YuSpacer size={'XL'} />
        <YuSkeleton height="32px" width="90%" alignSelf="center" />
        <YuSpacer size={'XS'} />
        <YuSkeleton height="32px" width="90%" alignSelf="center" />
        <YuSpacer size={'XS'} />
        <YuSkeleton height="32px" width="50%" alignSelf="center" />
        <YuSpacer size={'L'} />
        <YuSkeleton height="18px" width="70%" alignSelf="center" />
        <YuSpacer size={'XXS'} />
        <YuSkeleton height="18px" width="75%" alignSelf="center" />
        <YuSpacer size={'XXS'} />
        <YuSkeleton height="18px" width="65%" alignSelf="center" />
        <YuSpacer size={'L'} />
        <YuSkeleton height="48px" width="239px" alignSelf="center" />
        <YuSpacer size={'XL'} />
        <YuSkeletonWrapper
          height={'520px'}
          width="100%"
          backgroundColor="bg.grey"
        >
          <YuFlex
            flexDirection="column"
            paddingTop={'XL'}
            maxWidth={'874px'}
            width={'100%'}
          >
            <YuSkeleton height="32px" width="90%" alignSelf="center" />
            <YuSpacer size={'XS'} />
            <YuSkeleton height="32px" width="60%" alignSelf="center" />
            <YuSpacer size={'L'} />
            <YuSkeleton height="18px" width="90%" alignSelf="center" />
            <YuSpacer size={'XS'} />
            <YuSkeleton height="18px" width="70%" alignSelf="center" />
            <YuSpacer size={'L'} />
            <YuSkeleton height="48px" width="239px" alignSelf="center" />
          </YuFlex>
        </YuSkeletonWrapper>

        <YuSpacer size={'XL'} />
        <YuSkeleton height="32px" width="90%" alignSelf="center" />
        <YuSpacer size={'XS'} />
        <YuSkeleton height="32px" width="90%" alignSelf="center" />
        <YuSpacer size={'XS'} />
        <YuSkeleton height="32px" width="50%" alignSelf="center" />
        <YuSpacer size={'L'} />
        <YuSkeleton height="18px" width="70%" alignSelf="center" />
        <YuSpacer size={'XXS'} />
        <YuSkeleton height="18px" width="75%" alignSelf="center" />
        <YuSpacer size={'XXS'} />
        <YuSkeleton height="18px" width="65%" alignSelf="center" />
        <YuSpacer size={'L'} />
        <YuSkeleton height="48px" width="239px" alignSelf="center" />
        <YuSpacer size={'XL'} />
        <YuSkeleton height={fullHeight} width="100%" />
        <YuSpacer size={'XL'} />

        <YuSkeleton height="32px" width="50%" alignSelf="center" />
        <YuSpacer size={'XS'} />
        <YuSkeleton height="32px" width="70%" alignSelf="center" />
        <YuSpacer size={'XS'} />
        <YuSkeleton height="32px" width="50%" alignSelf="center" />
        <YuSpacer size={'XL'} />
        <YuFlex flexDirection="column">
          <YuSkeleton height="100px" alignSelf="center" width="100px" />
          <YuSpacer size={'M'} />
          <YuSkeleton height="28px" alignSelf="center" width="70%" />
          <YuSpacer size={'S'} />
          <YuSkeleton height="18px" alignSelf="center" width="80%" />
          <YuSpacer size={'XXS'} />
          <YuSkeleton height="18px" alignSelf="center" width="90%" />
          <YuSpacer size={'XXS'} />
          <YuSkeleton height="18px" alignSelf="center" width="80%" />

          <YuSpacer size={'L'} />
          <YuSpacer size={'XXS'} />

          <YuSkeleton height="100px" alignSelf="center" width="100px" />
          <YuSpacer size={'M'} />
          <YuSkeleton height="28px" alignSelf="center" width="70%" />
          <YuSpacer size={'S'} />
          <YuSkeleton height="18px" alignSelf="center" width="80%" />
          <YuSpacer size={'XXS'} />
          <YuSkeleton height="18px" alignSelf="center" width="90%" />
          <YuSpacer size={'XXS'} />
          <YuSkeleton height="18px" alignSelf="center" width="80%" />
          <YuSpacer size={'L'} />
          <YuSpacer size={'XXS'} />
          <YuSkeleton height="100px" alignSelf="center" width="100px" />
          <YuSpacer size={'M'} />
          <YuSkeleton height="28px" alignSelf="center" width="70%" />
          <YuSpacer size={'S'} />
          <YuSkeleton height="18px" alignSelf="center" width="80%" />
          <YuSpacer size={'XXS'} />
          <YuSkeleton height="18px" alignSelf="center" width="90%" />
          <YuSpacer size={'XXS'} />
          <YuSkeleton height="18px" alignSelf="center" width="80%" />
        </YuFlex>
        <YuSpacer size={'L'} />
        <YuSkeleton height="48px" alignSelf="center" width="239px" />
        <YuSpacer size={'XL'} />
      </YuSkeletonWrapper>
    );
  };

  if (!domLoaded) {
    return null;
  }
  return upXS ? <SkeletonPublicHomeDesktop /> : <SkeletonPublicHomeMobile />;
};

export default PublicHomeSkeleton;
