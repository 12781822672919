import { Row } from 'antd';
import { SkeletonChallengeCard } from './ChallengeCard.skeleton';
import { SkeletonButton } from './Button.skeleton';
import { SkeletonTitle } from './Title.skeleton';

export const SkeletonChallengeCarousel = (): JSX.Element => {
  return (
    <div className="skeleton-challenge-carousel">
      <SkeletonTitle />
      <Row
        justify="center"
        align="middle"
        className="skeleton-challenge-carousel--description"
      >
        <div className="skeleton-challenge-carousel--description__content" />
        <div className="skeleton-challenge-carousel--description__content-mobile" />
      </Row>
      <div className="skeleton-challenge-carousel--cards">
        <SkeletonChallengeCard />
        <SkeletonChallengeCard />
      </div>
      <Row
        justify="center"
        align="middle"
        className="skeleton-challenge-carousel--button"
      >
        <SkeletonButton />
      </Row>
    </div>
  );
};
