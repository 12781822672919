import { SkeletonButton } from './Button.skeleton';

export const SkeletonProductCard = (): JSX.Element => {
  return (
    <section className="skeleton-product-card">
      <div className="skeleton-product-card--media" />
      <div className="skeleton-product-card--title" />
      <div className="skeleton-product-card--details">
        <div className="skeleton-product-card--details__item" />
        <div className="skeleton-product-card--details__item" />
      </div>
      <div className="skeleton-product-card--link">
        <SkeletonButton />
      </div>
    </section>
  );
};
