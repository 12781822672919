import { NextPage } from 'next';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { privateRoute } from 'services/privateRoute';
import { IRootReducers } from 'src/reducers';
import { fetchHomePage } from 'src/actions/appActions';
import { getUserInitialData } from 'src/actions/authActions';
import PrivateHomeSkeleton from '../components/skeletonComponents/Pages/PrivateHome.skeleton';
import PublicHomeSkeleton from '../components/skeletonComponents/Pages/PublicHome.skeleton';
import { StaticPage } from 'src/components/staticComponents';
import TypeformNPS from 'src/components/typeforms/nps/TypeFormNPS';
import { IUser } from '../shared/models';
import { UserStatusEnum } from '../shared/enums';

interface OwnProps {
  children: JSX.Element;
  fetchCommercialSubscriptionRecruitmentDate;
  fetchHomePage: (token: string) => Promise<void>;
  getUserInitialData: (
    at: string,
    rt: string,
    user: IUser,
    userStatus: UserStatusEnum
  ) => Promise<void>;
}

type HomeProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  OwnProps;

const Home: NextPage = ({
  accessToken,
  refreshToken,
  user,
  userStatus,
  homePage,
  fetchHomePage,
  getUserInitialData,
}: HomeProps): JSX.Element => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const handleLoadInitialData = async () => {
    const isUserLogged = !!accessToken;
    setIsLoading(true);

    if (homePage && !homePage.publicPage && isUserLogged) {
      setIsLoading(false);
      return;
    }

    if (homePage && homePage.publicPage && !isUserLogged) {
      setIsLoading(false);
      return;
    }

    if (isUserLogged) {
      await getUserInitialData(accessToken, refreshToken, user, userStatus);
    }

    await fetchHomePage(accessToken);

    setIsLoading(false);
  };

  useEffect(() => {
    handleLoadInitialData();
  }, [accessToken]);

  return (
    <div className={`homePage ${accessToken ? '' : 'homePage__public'}`}>
      {isLoading &&
        (accessToken ? <PrivateHomeSkeleton /> : <PublicHomeSkeleton />)}
      {homePage && !isLoading && <StaticPage {...homePage} />}
      {accessToken && (
        <div className="ant-row">
          <TypeformNPS user={user} />
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state: IRootReducers) => {
  return {
    accessToken: state.auth.accessToken,
    refreshToken: state.auth.refreshToken,
    user: state.auth.user,
    userStatus: state.auth.userStatus,
    userHome: state.app.userHome,
    homePage: state.app.homePage,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators(
    {
      getUserInitialData,
      fetchHomePage,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  privateRoute({
    WrappedComponent: Home,
    canBePublic: true,
  })
);
