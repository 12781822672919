import { Row } from 'antd';
import { SkeletonTitle } from './Title.skeleton';
import { SkeletonProductCard } from './ProductCard.skeleton';
import { SkeletonButton } from './Button.skeleton';

export const SkeletonProductsGrid = (): JSX.Element => {
  return (
    <div className="skeleton-products-grid">
      <SkeletonTitle />
      <Row
        justify="center"
        align="middle"
        className="skeleton-challenge-carousel--description"
      >
        <div className="skeleton-challenge-carousel--description__content" />
        <div className="skeleton-challenge-carousel--description__content-mobile" />
      </Row>
      <div className="skeleton-products-grid--cards">
        <SkeletonProductCard />
        <SkeletonProductCard />
        <SkeletonProductCard />
        <SkeletonProductCard />
      </div>
      <div className="skeleton-products-grid--button">
        <SkeletonButton />
      </div>
    </div>
  );
};
