import { Row } from 'antd';

export const SkeletonImageCarousel = (): JSX.Element => {
  return (
    <Row justify="center" align="middle" className="skeleton-imageCarousel">
      <div className="skeleton-imageCarousel--media" />
      <ul className="skeleton-imageCarousel--dots">
        <li />
        <li />
        <li />
        <li />
      </ul>
    </Row>
  );
};
